import { useStore } from '~/components/Hook';

import classNames from 'classnames/bind';
import styles from './Profile.module.scss';
import EditProfileDialog from './EditProfileDialog';
import Avatar from './Avatar';
import ProfileCover from './ProfileCover';
const cx = classNames.bind(styles);
function ProfileHeader({ user }) {
    const store = useStore();
    const currentUser = store.state.user;
    return (
        <div className={cx('wrapper')}>
            <div className={cx('profileCover')}>
                <ProfileCover inputUser={user}></ProfileCover>
            </div>
            <div className={cx('DivShareInfo')}>
                <div className={cx('DivContainer')} data-e2e="user-avatar">
                    <div className={cx('DivContainerAvatar')}>
                        <Avatar inputUser={user}></Avatar>
                    </div>
                </div>
                <div className={cx('DivShareTitleContainer')}>
                    <div>
                        <h2 data-e2e="user-title" className={cx('H2ShareTitle')}>
                            {user.TenNhanVien}
                        </h2>
                        <h1 data-e2e="user-subtitle" className={cx('H1ShareSubTitle')}>
                            {user.ChucVu}
                        </h1>
                    </div>
                    {user.MaNhanVien === currentUser.MaNhanVien && <EditProfileDialog></EditProfileDialog>}
                </div>
            </div>
            <span style={{ padding: ' 0px 40px', marginTop: '12px' }} className={cx('linebreak')} />
        </div>
    );
}

export default ProfileHeader;
