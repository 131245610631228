function Coin() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>BIM coin là phần thưởng khi bạn hoàn thành đúng hạn công việc được giao.</h2>
            <h2>
                Ngoài ra khi công việc được đánh giá tốt / bị comment nhiều thì Leader sẽ cộng / trừ 1 lượng tương ứng.
            </h2>
            <h2>Tóm lại đây là công cụ để đánh giá chất lượng công việc.</h2>
            <h2>Vì vậy số lượng BIM coin bạn sở hữu là chỉ số quan trọng trong các đợt đánh giá lương / thưởng.</h2>
            <h2>Tính năng này đang được xây dựng...</h2>
            <img
                style={{ width: '70%', padding: '8px' }}
                title="budio_hero_illustration_for_animation_2.gif"
                src="https://cdn.dribbble.com/users/56427/screenshots/6003020/budio_hero_illustration_for_animation_2.gif"
                alt="budio_hero_illustration_for_animation_2"
            ></img>
        </div>
    );
}

export default Coin;
