import { useState } from 'react';
import { faKey, faUser, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { useStore } from '~/components/Hook';
import { auth, getToken } from '~/services/authService';
import styles from './Register.module.scss';

const cx = classNames.bind(styles);
function RegisterForm(data) {
    const [logError, setLogError] = useState(false);
    const store = useStore();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = await getToken();
        const dataAuth = {
            username: e.target.username.value,
            password: e.target.password.value,
            _csrf: token,
            setError: setLogError,
        };
        const res = await auth(dataAuth);
        if (res) {
            store.storage.setData({ jobs: res.jobs });
            store.dispatch(store.actions.setAcc(res));
        }
    };
    return (
        <div className={cx('container')}>
            <div className={cx('card')}>
                <div className={cx('card-header')}>
                    <h3>Login</h3>
                </div>
                <div className={cx('card-body')}>
                    {logError && (
                        <section className={cx('errors')}>
                            <FontAwesomeIcon fontSize={'20px'} icon={faWarning} />
                            <div className={cx('alert-login-fail')}>{logError}</div>
                        </section>
                    )}
                    <form onSubmit={handleSubmit} className={cx('input-from')}>
                        <div className={cx('input-group')}>
                            <div className={cx('input-group-prepend')}>
                                <span className={cx('input-group-text')}>
                                    <FontAwesomeIcon fontSize={'16px'} icon={faUser} />
                                </span>
                            </div>
                            <input type="text" name="username" className={cx('form-control')} placeholder="Username" />
                        </div>
                        <div className={cx('input-group')}>
                            <div className={cx('input-group-prepend')}>
                                <span className={cx('input-group-text')}>
                                    <FontAwesomeIcon fontSize={'16px'} icon={faKey} />
                                </span>
                            </div>
                            <input
                                type="password"
                                name="password"
                                className={cx('form-control')}
                                placeholder="Password"
                            />
                        </div>

                        <input type="hidden" name="_csrf" value={data.csrfToken} />
                        <div className="form-group">
                            <input type="submit" value="Login" className={cx('login_btn')} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RegisterForm;
