import Tippy from '@tippyjs/react';
import { useRef, useState } from 'react';
import './tippy.css';
import classNames from 'classnames/bind';
import {
    CheckImportanceIcon,
    CoinIcon,
    CompleteCheckIcon,
    CompletedIcon,
    CompletingIcon,
    ImportanceIcon,
} from '~/components/Icons';
import styles from './Job.module.scss';
import { Badge } from '@mui/material';
import JobMarkBadge from './JobMarkBadge';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { FinishJob, handleUpdateJob, UpdateStatusJob, WorkToday } from '~/services/jobService';
import * as JobConstants from './constains';
import DialogResult from './DialogResult';
import { useStore } from '~/components/Hook';
import DisabledOptions from './DisabledOptions';
const cx = classNames.bind(styles);
function Job({ data, inday, reported = false, setSTT }) {
    // console.log('job re-render');
    // if (data.MaCongViec === 3819) console.log(data);
    //const random = Math.floor(Math.random() * 4);
    const ERROR = 'error';
    const SUCCESS = 'success';
    const WARNING = 'warning';
    const PRIMARY = 'primary';

    if (data.Status === JobConstants.STATUS_LATED) data.status = ERROR;
    if (data.Status === JobConstants.STATUS_OK) data.status = SUCCESS;
    if (data.Status === JobConstants.STATUS_DELAY) data.status = WARNING;
    if (data.Status === JobConstants.STATUS_NOTYET) data.status = PRIMARY;
    // if (data.Link || data.Link === '') data.status = 'secondary';
    const store = useStore();
    const inputRef = useRef(0);
    const [status, setStatus] = useState(data.status);
    const [completed, setComplete] = useState(data.Link || data.Link === '');
    const [importance, setImportance] = useState(data.WorkToday === 'TRUE');
    const [timeToDay, setTimeToDay] = useState(reported ? data.ThoiGianHomNay : data.TimeToDay || 0);
    const [percentToDay, setPercentToDay] = useState(data.PercentToDay || data.PhanTramHienTai);
    const handleSetTimeToday = (value) => {
        if (value < 0 || value > 23) {
            data.WillReport = false;
            handleUpdateJob(store.storage, data);
        } else {
            setTimeToDay(value);
            data.TimeToDay = value;
            if (value > 0) {
                if (percentToDay !== 0) {
                    data.WillReport = true;
                }
                handleUpdateJob(store.storage, data);
            } else {
                data.WillReport = false;
                handleUpdateJob(store.storage, data);
            }
        }

        setSTT((pre) => pre + 1);
    };
    const handleSetPercentToday = (value) => {
        if (value < 0 || value > 100) {
            data.WillReport = false;
            handleUpdateJob(store.storage, data);
        } else {
            setPercentToDay(value);
            data.PercentToDay = value;
            if (value > 0) {
                if (timeToDay !== 0) {
                    data.WillReport = true;
                }
                handleUpdateJob(store.storage, data);
            } else {
                data.WillReport = false;
                handleUpdateJob(store.storage, data);
            }
        }
        setSTT((pre) => pre + 1);
    };
    // useEffect(() => {
    //     if (percentToDay !== 0 && timeToDay !== 0 && timeToDay !== initTimeToDay) {
    //         console.log('job run useEffect');
    //         data.WillReport = true;
    //         handleUpdateJob(store.storage,data);
    //         setSTT((pre) => pre + 1);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [percentToDay, timeToDay]);
    // const handleUpdateJob = (res) => {
    //     const newjobs = store.storage.data.jobs.map((item) => {
    //         if (item.MaCongViec === data.MaCongViec) {
    //             const newJob = { ...item, ...res };
    //             return newJob;
    //         }
    //         return item;
    //     });
    //     store.storage.setData({ jobs: newjobs });
    // };
    const handleChangeImportance = async (event) => {
        event.stopPropagation();
        if (!(reported || completed)) {
            const res = await WorkToday(data.MaNhanVien, data.MaCongViec, data.MaDuAn, !importance);
            //const res1 = await createManDay(data.MaNhanVien);
            if (res) {
                handleUpdateJob(store.storage, res);
                setImportance((pre) => !pre);
                setSTT((pre) => pre + 1);
            }
        }
    };
    const handleChangeComplete = async (event) => {
        event.stopPropagation();
        if (reported) return;
        if (completed) {
            // const result1 = await handleChangeJobStatus();
            const result2 = await FinishJob({
                maCongViec: data.MaCongViec,
                maNhanVien: data.MaNhanVien,
            });
            // if (result1 && result2) {
            if (result2) {
                handleUpdateJob(store.storage, result2);
                setComplete((pre) => !pre);
            }
        } else {
            inputRef.current.handleClickOpen();
            //  document.body.onfocus = checkIt;
        }
    };
    // async function getDir() {
    //     // const fileHandle = await window.showSaveFilePicker();

    //     // // run code for fileHandle
    //     // console.dir(fileHandle);
    //     // const fileData = await fileHandle.getFile();
    //     // console.dir(fileData);

    //     const dirHandle = await window.showDirectoryPicker();
    //     console.dir(dirHandle);
    //     // const path = await dirHandle.resolve(fileHandle);
    //     // console.dir(path);
    // }
    // const checkIt = () => {
    //     setTimeout(async () => {
    //         setComplete((pre) => !pre);
    //         document.body.onfocus = null;
    //         var link = '';
    //         if (inputRef.current.files.length) {
    //             // alert('Files Loaded');
    //             const text = await navigator.clipboard.readText();
    //             console.log(text);
    //             console.dir(inputRef.current.files[0]);
    //             link = inputRef.current;
    //         } else {
    //             //alert('Cancel clicked');
    //         }
    //         var dataToSend = { maCongViec: data.MaCongViec, maNhanVien: data.MaNhanVien, link: link };
    //         // const res = await FinishJob(dataToSend);
    //         // if (res) setComplete((pre) => !pre);
    //     }, 100);
    // };

    //console.log(data);
    const deadline = new Date(data.DeadLine);
    const removeWorkToday = async () => {
        if (data.WorkToday === 'TRUE') {
            const res = await WorkToday(data.MaNhanVien, data.MaCongViec, data.MaDuAn, false);
            if (res) {
                handleUpdateJob(store.storage, res);
                setImportance(false);
                setSTT((pre) => pre + 1);
            }
        }
    };
    // if (data.Comment !== DateToISOstring(Date.now())) {
    //     data.Worktoday = false;
    //     removeWorkToday();
    // }
    if (reported) {
        if (timeToDay !== data.ThoiGianHomNay) setTimeToDay(data.ThoiGianHomNay);
        if (percentToDay !== data.PhanTramHienTai) setPercentToDay(data.PhanTramHienTai);
    }
    const handleChangeJobStatus = async () => {
        var giodukien100 = data.ThoiGianDuKien;
        var cal_status;
        if (data.PhanTramHienTai !== 0) {
            giodukien100 = (data.ThoiGianThucHien * 100) / data.PhanTramHienTai;
            const heso = giodukien100 / data.ThoiGianDuKien;
            const chenhlech = giodukien100 - data.ThoiGianDuKien;
            if (heso <= 1) {
                cal_status = JobConstants.STATUS_OK;
            } else if (heso < 1.5 || chenhlech <= 8) {
                cal_status = JobConstants.STATUS_DELAY;
            } else {
                cal_status = JobConstants.STATUS_LATED;
            }
        } else {
            if (data.ThoiGianThucHien !== 0) {
                cal_status = JobConstants.STATUS_LATED;
            } else {
                cal_status = JobConstants.STATUS_NOTYET;
            }
        }
        var result;
        if (cal_status === JobConstants.STATUS_NOTYET && status !== PRIMARY) {
            result = await UpdateStatusJob(data.MaNhanVien, data.MaCongViec, cal_status);
            setStatus(PRIMARY);
        }
        if (cal_status === JobConstants.STATUS_DELAY && status !== WARNING) {
            result = await UpdateStatusJob(data.MaNhanVien, data.MaCongViec, cal_status);
            setStatus(WARNING);
        }
        if (cal_status === JobConstants.STATUS_LATED && status !== ERROR) {
            result = await UpdateStatusJob(data.MaNhanVien, data.MaCongViec, cal_status);
            setStatus(ERROR);
        }
        if (cal_status === JobConstants.STATUS_OK && status !== SUCCESS) {
            result = await UpdateStatusJob(data.MaNhanVien, data.MaCongViec, cal_status);
            setStatus(SUCCESS);
        }

        // if (data.MaCongViec === 3897) console.log(cal_status, status);
        return result;
    };
    if (!reported) handleChangeJobStatus();
    return (
        <div className="">
            <div role="none" draggable="true">
                <div>
                    <div className={cx('taskItem', status, { disabled: completed }, { reported })}>
                        <Badge
                            className={cx('badge-root')}
                            badgeContent={<JobMarkBadge status={status} />}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        ></Badge>
                        <div
                            className={cx('taskItem-body', { disabled: completed }, { reported })}
                            aria-live="off"
                            role="listitem"
                        >
                            <div className={cx('tittle-wrapper')}>
                                {!inday && (
                                    <Tippy
                                        theme="light"
                                        delay={[0, 50]}
                                        content="Nộp bài"
                                        disabled={completed || reported}
                                        placement="bottom"
                                    >
                                        <div
                                            className={cx(
                                                'checkBox',
                                                {
                                                    disabled: completed,
                                                },
                                                { reported },
                                            )}
                                            onClick={handleChangeComplete}
                                        >
                                            {!completed && (
                                                <CompleteCheckIcon
                                                    className={cx('fluentIcon', 'hideOnhover', {
                                                        iconsDisabled: completed || reported,
                                                    })}
                                                />
                                            )}
                                            {!completed && (
                                                <CompletingIcon
                                                    className={cx('fluentIcon', 'showOnhover', {
                                                        iconsDisabled: completed || reported,
                                                    })}
                                                />
                                            )}
                                            {completed && <CompletedIcon className={cx('fluentIcon', 'completed')} />}
                                            {/* <input
                                                className={cx('Input')}
                                                ref={inputRef}
                                                type="file"
                                                name="image"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                }}
                                            /> */}
                                            <DialogResult
                                                className={cx('Input')}
                                                data={data}
                                                ref={inputRef}
                                                setComplete={setComplete}
                                                removeWorkToday={removeWorkToday}
                                            />
                                        </div>
                                    </Tippy>
                                )}
                                <button
                                    onClick={handleChangeImportance}
                                    tabIndex="-1"
                                    className={cx('taskItem-titleWrapper', { disabled: completed }, { reported })}
                                >
                                    <h6>DỰ ÁN : {data.TenDuAn}</h6>
                                    <span className={cx({ completed })}>{data.TenCongViec}</span>
                                </button>
                            </div>
                            {!inday ? (
                                <div className={cx('jobInfo')} onClick={handleChangeImportance}>
                                    <h3 className={cx('coin')}>{data.ThoiGianChuan}</h3>
                                    <CoinIcon></CoinIcon>
                                    <span className={cx('span-deadline')}>
                                        <h6>ĐÃ THỰC HIỆN</h6>
                                        {data.ThoiGianThucHien + 'h / ' + data.ThoiGianDuKien + 'h'}
                                    </span>
                                    <span className={cx('span-center')}>
                                        <CircularProgressWithLabel value={data.PhanTramHienTai} color={status} />
                                    </span>
                                    <span className={cx('span-deadline')}>
                                        <h6>HẠN NỘP</h6>
                                        {('0' + deadline.getDate()).slice(-2) +
                                            '/' +
                                            ('0' + (deadline.getMonth() + 1)).slice(-2)}
                                    </span>
                                </div>
                            ) : (
                                <div className={cx('jobInfo')}>
                                    <DisabledOptions
                                        label="Thời gian"
                                        timeToDay={timeToDay}
                                        handleSetTimeToday={handleSetTimeToday}
                                        disabled={reported}
                                    />
                                    <DisabledOptions
                                        label="Phần trăm"
                                        timeToDay={percentToDay}
                                        handleSetTimeToday={handleSetPercentToday}
                                        disabled={reported}
                                        percent
                                    />
                                </div>
                            )}
                            {!completed && !inday && (
                                <span onClick={handleChangeImportance}>
                                    {!importance ? (
                                        <Tippy
                                            theme="light"
                                            delay={[0, 50]}
                                            content="Đánh dấu công việc trong ngày"
                                            placement="bottom"
                                            disabled={completed || reported}
                                        >
                                            <div className={cx('importanceButton', 'span-center')}>
                                                <ImportanceIcon
                                                    className={cx('fluentIcon', { iconsDisabled: reported })}
                                                />
                                            </div>
                                        </Tippy>
                                    ) : (
                                        <Tippy
                                            theme="light"
                                            delay={[0, 50]}
                                            content="Loại bỏ"
                                            placement="bottom"
                                            disabled={completed || reported}
                                        >
                                            <div className={cx('importanceButton', 'span-center')}>
                                                <CheckImportanceIcon className={cx('fluentIcon', { reported })} />
                                            </div>
                                        </Tippy>
                                    )}
                                </span>
                            )}
                            {completed && (
                                <span>
                                    <div className={cx('importanceButton', 'span-center')}>
                                        <ImportanceIcon className={cx('fluentIcon', 'iconsDisabled')} />
                                    </div>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Job;
