import { TaskAlt, Error, WarningAmber } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './Job.module.scss';
const cx = classNames.bind(styles);
function JobMarkBadge({ status }) {
    var icon;
    if (status === 'success') icon = <TaskAlt sx={{ fontSize: 20 }} />;

    if (status === 'warning') icon = <WarningAmber sx={{ fontSize: 20 }} />;
    if (status === 'error') icon = <Error sx={{ fontSize: 20 }} />;
    // if (status === 'ok') icon = <ThumbUpAlt sx={{ fontSize: 20 }} />;
    return (
        <div className={cx('badgeWrapper', 'badge-job-' + status)}>
            <div className={cx('badgeItem')}>{icon}</div>
        </div>
    );
}

export default JobMarkBadge;
