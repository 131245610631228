import { SkipNext, SkipPrevious } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useStore } from '~/components/Hook';
import { loadTimeSheet } from '~/services/timeSheetService';
import CustomizedTables from './CustomizedTables';
import classNames from 'classnames/bind';
import styles from './TimeSheet.module.scss';
import { IconButton } from '@mui/material';
const cx = classNames.bind(styles);

function TimeSheet() {
    const store = useStore();
    const [rows, setRows] = useState([]);
    const [today] = useState(new Date());
    //const today = new Date('2021-09-01');
    // const month = today.getMonth();
    // const year = today.getFullYear();
    const handleLoadTimeSheet = async (today) => {
        const loadedRows = await loadTimeSheet(store.state.user, today.getMonth() + 1, today.getFullYear());
        setRows(loadedRows);
    };
    useEffect(() => {
        if (store.state.user) handleLoadTimeSheet(today);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.state, today]);
    const handlePre = () => {
        today.setMonth(today.getMonth() - 1);
        if (store.state.user) handleLoadTimeSheet(today);
    };
    const handleNext = () => {
        today.setMonth(today.getMonth() + 1);
        if (store.state.user) handleLoadTimeSheet(today);
    };
    return (
        <>
            <div className={cx('header')}>
                <IconButton size="small" onClick={handlePre}>
                    <SkipPrevious sx={{ fontSize: '32px', color: 'black' }} />
                </IconButton>
                <h2>{today.getMonth() + 1 + ' / ' + today.getFullYear()}</h2>
                <IconButton size="small" onClick={handleNext}>
                    <SkipNext sx={{ fontSize: '32px', color: 'black' }} />
                </IconButton>
            </div>
            <div className={cx('content')}>
                <CustomizedTables rows={rows}></CustomizedTables>
            </div>
        </>
    );
}

export default TimeSheet;
