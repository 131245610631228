import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';

import App from '~/App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './components/store';
import GlobalStyles from './components/GlobalStyles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <GlobalStyles>
        <Fragment>
            {/* <BackGroundVideo id="hG0p69cxArI"></BackGroundVideo> */}
            <StoreProvider>
                <App />
            </StoreProvider>
        </Fragment>
    </GlobalStyles>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
