import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StyledTableCell, StyledTableRow } from './CustomizedTables';
function TimeSheetRow({ row }) {
    const [open, setOpen] = React.useState(false);
    const subclose = open ? '' : ' subclose';
    return (
        <React.Fragment>
            <StyledTableRow key={row.Id} className={row.Thu}>
                <StyledTableCell align="center">{row.Stt}</StyledTableCell>
                <StyledTableCell align="right">{row.Thu ? row.Thu + ': ' + row.Date : '' + row.Date}</StyledTableCell>
                <StyledTableCell className={'ts_worktime ' + row.Main} align="center">
                    {row.WorkTime}
                </StyledTableCell>
                <StyledTableCell className={'ts_overtime ' + row.Main} align="center">
                    {row.OverTime}
                </StyledTableCell>
                <StyledTableCell className={'ts_project ' + row.Main} align="center">
                    {row.Project}
                    {row.SubRows && true && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? (
                                <KeyboardArrowUpIcon fontSize="medium" />
                            ) : (
                                <KeyboardArrowDownIcon fontSize="medium" />
                            )}
                        </IconButton>
                    )}
                </StyledTableCell>
                <StyledTableCell align="center">{row.StartWork}</StyledTableCell>
                <StyledTableCell align="center">{row.EndWork}</StyledTableCell>
            </StyledTableRow>
            {row.SubRows &&
                row.SubRows.map((subrow) => (
                    <StyledTableRow
                        sx={open ? {} : { '& > *': { borderBottom: 'unset' } }}
                        key={subrow.Id}
                        className={subrow.Thu}
                    >
                        <StyledTableCell className={subclose} align="center">
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {subrow.Stt}
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell className={subclose} align="left">
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {subrow.Thu ? subrow.Thu + ': ' + subrow.Date : '' + subrow.Date}
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell className={'ts_worktime ' + subrow.Main + subclose} align="center">
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {subrow.WorkTime}
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell className={'ts_overtime ' + subrow.Main + subclose} align="center">
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {subrow.OverTime}
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell className={'ts_project ' + subrow.Main + subclose} align="center">
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {subrow.Project}
                                {subrow.SubRows && (
                                    <IconButton aria-label="expand subrow" onClick={() => setOpen(!open)}>
                                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                )}
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell className={subclose} align="center">
                            {subrow.StartWork}
                        </StyledTableCell>
                        <StyledTableCell className={subclose} align="center">
                            {subrow.EndWork}
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
        </React.Fragment>
    );
}

export default TimeSheetRow;
