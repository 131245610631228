//import FormUpload from '~/components/FormUpload';

import { useStore } from '~/components/Hook';
//import BackGroundVideo from '~/layouts/components/BackGround/BackGroundVideo';
import TabCss from './TabCss';

function Home() {
    const store = useStore();
    const currentUser = store.state.user;
    return (
        <>
            {currentUser && <div>{<TabCss />}</div>}
            {/* {!currentUser && <BackGroundVideo id="WWJ672iZKNQ"></BackGroundVideo>} */}
        </>
    );
}

export default Home;
