import { SET_USER, LOG_OUT, SET_ACC, SET_JOBS } from './constains';

export const setUser = (payload) => ({
    type: SET_USER,
    payload: payload,
});
export const setAcc = (payload) => ({
    type: SET_ACC,
    payload: payload,
});
export const setJobs = (payload) => ({
    type: SET_JOBS,
    payload: payload,
});

export const logOut = () => ({
    type: LOG_OUT,
});
