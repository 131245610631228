import { UpdateLinkUser } from '~/components/store/reducer';
import nodeRequest from '~/ulti/nodeRequest';

export const UploadAvatar = async (formdata) => {
    try {
        const res = await nodeRequest.post('/user/avatar', formdata);
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const getUserByUsername = async (username) => {
    try {
        const res = await nodeRequest.get('user/name', {
            params: {
                username,
            },
        });
        return UpdateLinkUser(res.data);
        //return res.data;
    } catch (error) {
        console.log(error);
    }
};
