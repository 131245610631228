import nodeRequest from '~/ulti/nodeRequest';

export const getJob = async (user) => {
    try {
        const res = await nodeRequest.get('user/jobs', {
            user: user,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
// export const FinishJob = async (formdata) => {
//     try {
//         const res = await nodeRequest.post('/job/finish', formdata, {
//             headers: { 'Content-Type': undefined },
//             // headers: { 'Content-Type': 'multipart/form-data' },
//             // boundary: formdata._boundary,
//             //headers: { 'content-type': 'application/json' },
//         });
//         //const res = await nodeRequest.post('/job/finish', formdata);
//         return res;
//     } catch (error) {
//         console.log(error);
//     }
// };
export const FinishJob = async (data) => {
    try {
        const res = await nodeRequest.post('/job/finish', data);
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const UpdateStatusJob = async (maNhanVien, maCongViec, status) => {
    try {
        const res = await nodeRequest.post('job/status', {
            maNhanVien,
            maCongViec,
            status,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const WorkToday = async (maNhanVien, maCongViec, maDuAn, workToday) => {
    try {
        let status = 'FALSE';
        if (workToday) status = 'TRUE';
        const res = await nodeRequest.post('job/worktoday', {
            maNhanVien,
            maCongViec,
            maDuAn,
            status,
        });
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const ReportJob = async (job) => {
    try {
        const res = await nodeRequest.post('job/report', {
            job,
        });
        res.data.TimeToDay = 0;
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
export const handleUpdateJob = (storage, res) => {
    const newjobs = storage.data.jobs.map((item) => {
        if (item.MaCongViec === res.MaCongViec) {
            const newJob = { ...item, ...res };
            return newJob;
        }
        return item;
    });
    storage.setData({ jobs: newjobs });
};
