import { useEffect, useState } from 'react';
function useDebound(value, delay) {
    const [state, setState] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setState(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
    return state;
}
export default useDebound;
