import * as React from 'react';
import Button from '~/components/Button';
import Snackbar from '@mui/material/Snackbar';
import './DialogResult.css';

function SnackbarButton({ onClick }) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
        onClick();
    };
    const handleClose = (reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }
        setOpen(false);
    };
    return (
        <div>
            <Button text onClick={handleClick}>
                Copy qua Zalo
            </Button>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Copied to Clipboard !"
                //action={action}
            />
        </div>
    );
}

export default SnackbarButton;
