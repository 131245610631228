import Job from '../Home/Job';
import classNames from 'classnames/bind';
import styles from './Profile.module.scss';
const cx = classNames.bind(styles);

function ProfileBody({ jobs = [] }) {
    return (
        <div className={cx('body-wrapper')} style={{ marginBottom: '40px' }}>
            {jobs.map((jobItem) => {
                return <Job key={jobItem.MaCongViec} data={jobItem} reported></Job>;
            })}
        </div>
    );
}

export default ProfileBody;
