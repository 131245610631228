import { Fragment, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import Tippy from '@tippyjs/react/headless';
import PropTypes from 'prop-types';

import { Wrapper as PopperWrapper } from '~/layouts/components/Popper';
import MenuItem from './MenuItem';
import styles from './Menu.module.scss';
import Header from './Header';
const cx = classNames.bind(styles);

function Menu({ children, items = [] }) {
    const [history, setHistory] = useState([{ children: items }]);
    //const [visible, setVisible] = useState(true);
    const tippyref = useRef();
    const current = history[history.length - 1];
    const renderItems = () => {
        return (
            <Fragment>
                {history.length > 1 && <Header title={current.title} onBack={handleBack}></Header>}
                <div className={cx('menu-body')}>
                    {current.children.map((item, index) => {
                        const isParent = !!item.children;
                        return (
                            <MenuItem
                                key={index}
                                data={item}
                                onClick={() => {
                                    if (isParent) {
                                        const newItem = { children: item.children, title: item.title };
                                        setHistory((prev) => [...prev, newItem]);
                                    } else {
                                        tippyref.current._tippy.hide();
                                        //console.dir(tippyref.current);
                                        // tippyref.current.hide();
                                        //setVisible(false);
                                        //item.onClick();
                                    }
                                }}
                            ></MenuItem>
                        );
                    })}
                </div>
            </Fragment>
        );
    };
    const renderTippy = (attrs) => (
        <div className={cx('menu-list')} tabIndex="-1" {...attrs}>
            <PopperWrapper className={cx('menu-popper')}>{renderItems()}</PopperWrapper>
        </div>
    );
    const handleResetMenu = () => {
        setHistory((prev) => prev.slice(0, 1));
    };
    const handleBack = () => {
        setHistory((prev) => prev.slice(0, prev.length - 1));
    };
    return (
        <Tippy
            ref={tippyref}
            onHide={handleResetMenu}
            //visible={visible}
            //hideOnClick={true}
            interactive={true}
            delay={[0, 500]}
            offset={[12, 8]}
            placement="bottom-end"
            render={renderTippy}
        >
            {children}
        </Tippy>
    );
}
Menu.propTypes = {
    children: PropTypes.node.isRequired,
    items: PropTypes.array,
};
export default Menu;
