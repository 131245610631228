import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import classNames from 'classnames/bind';
import styles from './DialogResult.module.scss';
import { FinishJob, handleUpdateJob } from '~/services/jobService';
import { useStore } from '~/components/Hook';
const cx = classNames.bind(styles);

function DialogResult(props, ref) {
    const store = useStore();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const handleClose = (event) => {
        event.stopPropagation();
        setOpen(false);
    };
    const handleSubmit = async (event) => {
        event.stopPropagation();
        var dataToSend = {
            maCongViec: props.data.MaCongViec,
            maNhanVien: props.data.MaNhanVien,
            link: value,
        };
        const res = await FinishJob(dataToSend);
        if (res) {
            handleUpdateJob(store.storage, res);
            props.setComplete((pre) => !pre);
            await props.removeWorkToday();
        }
        setOpen(false);
    };
    React.useImperativeHandle(ref, () => ({
        handleClickOpen() {
            setOpen(true);
        },
    }));
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
            <Dialog open={open} onClose={handleClose} className={cx('wrapper')}>
                <DialogTitle className={cx('tittle')}>NỘP BÀI</DialogTitle>
                <DialogContent className={cx('content')}>
                    <DialogContentText>Dán link bài nộp (nếu có).</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={value}
                        label="Đường dẫn kết quả"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>HỦY</Button>
                    <Button onClick={handleSubmit}>NỘP BÀI</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

//export default DialogResult;
export default React.forwardRef(DialogResult);
