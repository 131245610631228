import { DateToLocalstring, GetDateFromString, GetDatesOfMonth } from '~/ulti/helper';
import nodeRequest from '~/ulti/nodeRequest';

export const createManDay = async (maNhanVien) => {
    try {
        const res = await nodeRequest.post('timesheet/create', {
            maNhanVien,
        });
        return res.data !== undefined;
    } catch (error) {
        console.log(error);
    }
};
export const loadTimeSheetDemo = () => {
    const createData = (
        Id,
        Main,
        Stt,
        Thu,
        Date,
        WorkTime,
        OverTime,
        Project,
        StartWork,
        EndWork,
        SubRows = undefined,
    ) => {
        if (OverTime === 0) OverTime = '';
        return {
            Id,
            Main,
            Stt,
            Thu,
            Date,
            WorkTime,
            OverTime,
            Project,
            StartWork,
            EndWork,
            SubRows,
        };
    };
    return [
        createData(1, 'main', 1, 'T6', '02/12/2022', 7.25, 0, 'ASBUILT LOR', '8:35:38 SA', '5:10:50 CH'),
        createData(2, 'main', 2, 'T7', '03/12/2022', 4, 0, 'ASBUILT LOR', '8:35:38 SA', '5:10:50 CH'),
        createData(3, 'main', 3, 'CN', '04/12/2022', 0, 0, '', '', ''),
        createData(
            4,
            'main',
            4,
            'T2',
            '05/12/2022',
            8,
            2,
            'ASBUILT LOR, CÔNG VIỆC KHÁC, ...',
            '8:35:38 SA',
            '5:10:50 CH',
            [
                createData(6, '', '', '', '', 4, 0, 'ASBUILT LOR', '', ''),
                createData(6, '', '', '', '', 4, 2, 'CÔNG VIỆC KHÁC', '', ''),
            ],
        ),
        createData(5, 'main', 5, 'T3', '06/12/2022', 7, 0, 'ASBUILT LOR', '8:35:38 SA', '5:10:50 CH'),
    ];
};
const timeSheetInterceptor = function (res, Month, Year) {
    const createData = (inputData, Stt, main = true) => {
        const date = GetDateFromString(inputData.Ngay);
        const Thu = date.getDay() === 0 ? 'CN' : 'T' + (date.getDay() + 1);
        return {
            Id: inputData.ID,
            Main: main ? 'main' : '',
            Stt: main ? Stt : '',
            Thu: main ? Thu : '',
            Date: main ? DateToLocalstring(date) : '',
            WorkTime: inputData.GioCong,
            OverTime: inputData.OverTime === 0 ? '' : inputData.OverTime,
            Project: inputData.DuAN,
            StartWork: main ? GetDateFromString(inputData.Start).toLocaleTimeString() : '',
            EndWork: main ? GetDateFromString(inputData.End).toLocaleTimeString() : '',
            SubRows: undefined,
        };
    };
    if (res.data) {
        var result = [];
        for (let index = 1; index <= GetDatesOfMonth(Month, Year); index++) {
            const today = new Date(Year, Month - 1, index);
            const todayArray = res.data[0].filter((dateObject) => {
                const sameDate = GetDateFromString(dateObject.Ngay).getDate() === index;
                const zeroTime = dateObject.GioCong === 0 && dateObject.OverTime === 0;
                return sameDate && !zeroTime;
            });
            //console.log(today, todayArray.length);
            if (todayArray.length === 0) {
                // console.log(today, new Date());
                const data = {
                    Id: -index,
                    Main: 'main',
                    Stt: index,
                    Thu: today.getDay() === 0 ? 'CN' : 'T' + (today.getDay() + 1),
                    Date: DateToLocalstring(today),
                    WorkTime: '',
                    OverTime: '',
                    Project: '',
                    StartWork: '',
                    EndWork: '',
                };
                if (today.toDateString() === new Date().toDateString() && res.data[2]) {
                    data.StartWork = GetDateFromString(res.data[2]).toLocaleTimeString();
                }
                result.push(data);
            } else if (todayArray.length === 1) {
                const data = createData(todayArray[0], index);
                result.push(data);
            } else {
                const combine = { workTime: 0, overTime: 0, project: '', subTimeSheet: [] };
                // eslint-disable-next-line no-loop-func
                todayArray.reduce((combine, currentValue) => {
                    combine.workTime += currentValue.GioCong;
                    combine.overTime += currentValue.OverTime;
                    const pre_Project = (combine.project += currentValue.DuAN + ' ,');
                    if (pre_Project.length < 100) combine.project = pre_Project;
                    const element = createData(currentValue, index, false);
                    combine.subTimeSheet.push(element);

                    return combine;
                }, combine);
                const data = {
                    Id: -index,
                    Main: 'main',
                    Stt: index,
                    Thu: today.getDay() === 0 ? 'CN' : 'T' + (today.getDay() + 1),
                    Date: DateToLocalstring(today),
                    WorkTime: combine.workTime,
                    OverTime: combine.overTime === 0 ? '' : combine.overTime,
                    Project: combine.project + '...',
                    StartWork: GetDateFromString(todayArray[0].Start).toLocaleTimeString(),
                    EndWork: GetDateFromString(todayArray[0].End).toLocaleTimeString(),
                    SubRows: combine.subTimeSheet,
                };
                result.push(data);
            }
        }
        res.data = result;
    }
    return res;
};
export const loadTimeSheet = async (NhanVien, Month, Year) => {
    try {
        const res = await nodeRequest.post('timesheet/user', {
            NhanVien,
            MaNhanVien: NhanVien.MaNhanVien,
            Month,
            Year,
        });
        return timeSheetInterceptor(res, Month, Year).data;
        //return res.data;
    } catch (error) {
        console.log(error);
    }
};
