import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import classNames from 'classnames/bind';
import styles from './CircularProgressWithLabel.module.scss';
import { useEffect, useState } from 'react';
const cx = classNames.bind(styles);
function CircularProgressWithLabel(props) {
    const [value, setValue] = useState(0);
    useEffect(() => {
        const timer = setInterval(() => {
            setValue((prevProgress) => {
                if (prevProgress + 20 >= Math.round(props.value)) {
                    clearInterval(timer);
                    return Math.round(props.value);
                } else {
                    return prevProgress + 20;
                }
                // prevProgress + 20 >= Math.round(props.value) ? Math.round(props.value) : prevProgress + 20,
            });
            if (Math.round(props.value) === value) {
                clearInterval(timer);
            }
        }, 100);
        return () => {
            clearInterval(timer);
        };
        //setValue(Math.round(props.value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className={cx('circle-in', props.color)}></div>
            <CircularProgress variant="determinate" {...props} value={value} />
            <Box
                className={cx('box-content')}
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {`${Math.round(props.value)}%`}
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;
