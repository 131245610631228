import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import HeadlessTippy from '@tippyjs/react/headless';
import 'tippy.js/dist/tippy.css';

import { search } from '~/services/searchService';
import AccountItem from '~/layouts/components/AccountItem';
import { Wrapper as PopperWrapper } from '~/layouts/components/Popper';
import { SearchIcon } from '~/components/Icons';
import styles from './Search.module.scss';
import { useDebound } from '~/components/Hook';
const cx = classNames.bind(styles);
function Search() {
    const [searchResults, setSearchResults] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const searchInputDelay = useDebound(searchInput, 800);
    useEffect(() => {
        setLoading(true);
        if (searchInputDelay.length > 0) {
            const handleSearch = async () => {
                const res = await search(searchInputDelay);
                setSearchResults(res);
                setLoading(false);
                setShowResults(true);
            };
            handleSearch();
        } else {
            setSearchResults([]);
            setLoading(false);
        }
    }, [searchInputDelay]);
    return (
        <HeadlessTippy
            interactive={true}
            visible={showResults && searchResults.length > 0}
            render={(attrs) => (
                <div className={cx('popper')} tabIndex="-1" {...attrs}>
                    <PopperWrapper>
                        <div className={cx('acc-title')}>Account</div>
                        {searchResults.map((result) => {
                            return (
                                <AccountItem
                                    key={result.Id}
                                    fullName={result.ChucVu}
                                    nickName={result.TenNhanVien}
                                    tick={result.tick}
                                    avatar={result.Avatar}
                                    accName={result.userName}
                                    onClick={() => {
                                        setShowResults(false);
                                    }}
                                />
                            );
                        })}
                    </PopperWrapper>
                </div>
            )}
            onClickOutside={() => {
                setShowResults(false);
            }}
        >
            <div className={cx('search')}>
                <input
                    ref={inputRef}
                    value={searchInput}
                    type="text"
                    placeholder="Search accounts and names"
                    className={cx('input')}
                    spellCheck={false}
                    onChange={(event) => setSearchInput(event.target.value.trimStart())}
                    onFocus={() => setShowResults(true)}
                ></input>
                {!loading && searchInput.length > 0 && (
                    <button
                        className={cx('clear')}
                        onClick={() => {
                            setSearchInput('');
                            setSearchResults([]);
                            inputRef.current.focus();
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </button>
                )}
                {loading && <FontAwesomeIcon className={cx('loading')} icon={faSpinner} />}
                <button className={cx('search-btn')} onMouseDown={(e) => e.preventDefault()}>
                    <SearchIcon></SearchIcon>
                </button>
            </div>
        </HeadlessTippy>
    );
}

export default Search;
