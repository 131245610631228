import { LOG_OUT, SET_USER, SET_ACC, AVATAR_LINK, PROFILE_COVER_LINK } from './constains';
const initState = {
    account: undefined,
    user: undefined,
    // jobs: [],
};

function reducer(state, action) {
    switch (action.type) {
        case SET_ACC:
            action.payload.user = UpdateLinkUser(action.payload.user);
            // return { ...state, account: action.payload.account, user: action.payload.user, jobs: action.payload.jobs };
            return { ...state, account: action.payload.account, user: action.payload.user };
        case SET_USER:
            action.payload = UpdateLinkUser(action.payload);
            return { ...state, user: action.payload };
        // case SET_JOBS:
        //     return { ...state, jobs: action.payload };
        case LOG_OUT:
            return initState;
        default:
            throw new Error('Invalid action');
    }
}
function UpdateLinkUser(user) {
    if (user.Avatar && !user.Avatar.includes(AVATAR_LINK) && !user.Avatar.includes('blob')) {
        if (user.Avatar === 'default.png') {
            user.Avatar = AVATAR_LINK + user.Avatar;
        } else {
            user.Avatar = AVATAR_LINK + user.MaNhanVien + '/' + user.Avatar;
        }
    }
    if (user.ProfileCover && !user.ProfileCover.includes(PROFILE_COVER_LINK) && !user.ProfileCover.includes('blob')) {
        if (user.ProfileCover === 'default.png') {
            user.ProfileCover = PROFILE_COVER_LINK + user.ProfileCover;
        } else {
            user.ProfileCover = PROFILE_COVER_LINK + user.MaNhanVien + '/' + user.ProfileCover;
        }
    }
    user.tick = false;
    if (user.VaiTro === 7 || user.VaiTro === 2) user.tick = true;
    return user;
}

export { initState, UpdateLinkUser };
export default reducer;
