import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './DialogResult.css';
import classNames from 'classnames/bind';
import styles from './DialogResult.module.scss';
import { useDebound } from '~/components/Hook';
import { useState, useEffect } from 'react';
const cx = classNames.bind(styles);

export default function DisabledOptions({ label, timeToDay, handleSetTimeToday, percent = false, disabled = false }) {
    var option = timeSlots;
    var stringAfter = 'h';
    var initvalue = null;
    if (percent) {
        // console.log('percent re-render');
        option = percentSlots;
        stringAfter = '%';
    }
    if (timeToDay > 0) initvalue = `${timeToDay}${stringAfter}`;
    const [valueString, setValueString] = useState(initvalue);
    const valueInput = useDebound(valueString, 500);
    useEffect(() => {
        var value = 0;
        if (valueInput && valueInput !== initvalue) {
            if (valueString.includes(stringAfter)) value = Number(valueString.replace(stringAfter, ''));
            else value = Number(valueString);
            handleSetTimeToday(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueInput]);
    const handleInputChange = (event) => {
        if (event) {
            if (event.target.value && event.target.value !== initvalue) {
                setValueString(event.target.value);
            } else if (event.target.innerText && event.target.innerText !== initvalue) {
                setValueString(event.target.innerText);
            } else {
                setValueString('0');
            }
        }
    };

    return (
        <Autocomplete
            className={cx('wrapper-autocomplete')}
            value={initvalue}
            id="disabled-options-demo"
            onInputChange={handleInputChange}
            disabled={disabled}
            freeSolo
            options={option}
            getOptionDisabled={(option) => option === timeSlots[0] || option === percentSlots[0]}
            renderInput={(params) => {
                // params.value = value;
                return <TextField className={cx('content', 'autoComplete')} {...params} label={label} />;
            }}
        />
    );
}

// One time slot every 30 minutes.
const timeSlots = Array.from(new Array(24 * 2)).map((_, index) => `${index * 0.5}h`);
const percentSlots = Array.from(new Array(20)).map((_, index) => `${index * 5}%`);
