import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import styles from './TopHeader.module.scss';
const cx = classNames.bind(styles);
function TopHeader() {
    return (
        <section className={cx('section-default')}>
            <div className={cx('wrapper')}>
                <div className={cx('inner')}>
                    <div className={cx('top-item')}>
                        <FontAwesomeIcon icon={faEnvelope} className={cx('icons')} />
                        <h4 href="tel:0397979427">info@ibim.com.vn</h4>
                    </div>
                    <div className={cx('top-item', 'right')}>
                        <h4>T2 - T6 8:00am - 6:00pm / T7-CN CLOSED</h4>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopHeader;
