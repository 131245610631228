import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '~/components/Hook';
import { logOut } from '~/services/authService';
function Logout() {
    let navigate = useNavigate();
    const store = useStore();
    const handleLogOut = async () => {
        const result = await logOut();
        if (result.data === 'logged out') {
            store.storage.setData({ jobs: [] });
            store.dispatch(store.actions.logOut());
        }
    };
    handleLogOut();
    useEffect(() => {
        navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

export default Logout;
