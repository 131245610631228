import * as React from 'react';
import Image from '~/components/Image';
import { EditAvatarIcon } from '~/components/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '~/components/Hook';
import classNames from 'classnames/bind';
import styles from './Profile.module.scss';

const cx = classNames.bind(styles);
function Avatar({ inputUser = undefined, editmode = false }) {
    const inputRef = React.useRef();
    const formRef = React.useRef();
    const store = useStore();
    const currentUser = store.state.user;
    const [user, setUserComp] = React.useState(inputUser ? inputUser : currentUser);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!e.target.files) return;
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        const previewUser = { ...user, ...{ Avatar: objectUrl } };

        store.storage.setData({ previewAvatar: e.target.files[0] });
        // user.PreviewAvatar = e.target.files[0];
        //store.dispatch(store.actions.setUser(user));
        setUserComp(previewUser);
    };
    React.useEffect(() => {
        if (inputUser && !editmode) {
            setUserComp(inputUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputUser]);

    return (
        <div className={cx('AvatarWrapper')}>
            <Image
                src={user.Avatar}
                customFallback={process.env.REACT_APP_NODE_SERVER_URL + 'img/avatar/default.png'}
                alt="avatar"
                className={cx('ImgAvatar', { editmode })}
            ></Image>
            {user.tick && !editmode && (
                <FontAwesomeIcon
                    className={cx('info-tick', 'DivAvatarEditIcon')}
                    icon={faCheckCircle}
                ></FontAwesomeIcon>
            )}
            {editmode && (
                <div className={cx('DivAvatarEditIcon')} onClick={() => inputRef.current.click()}>
                    <EditAvatarIcon />
                    <form ref={formRef} encType="multipart/form-data">
                        <input
                            ref={inputRef}
                            type="file"
                            name="avatar"
                            accept=".jpg,.jpeg,.png,.tiff,.heic,.webp"
                            className={cx('InputUpload')}
                            onChange={handleSubmit}
                        />
                    </form>
                </div>
            )}
        </div>
    );
}

export default Avatar;
