import * as React from 'react';
import { useStore } from '~/components/Hook';
import Image from '~/components/Image';
import styles from './Profile.module.scss';
import classNames from 'classnames/bind';
import { EditAvatarIcon } from '~/components/Icons';
const cx = classNames.bind(styles);
function ProfileCover({ inputUser = undefined, editmode = false }) {
    const inputRef = React.useRef();
    const formRef = React.useRef();
    const store = useStore();
    const currentUser = store.state.user;
    const [user, setUser] = React.useState(inputUser ? inputUser : currentUser);
    const handleSubmit = (e) => {
        e.preventDefault();
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        const previewUser = { ...user, ...{ ProfileCover: objectUrl } };
        store.storage.setData({ previewCover: e.target.files[0] });
        // user.PreviewCover = e.target.files[0];
        // store.dispatch(store.actions.setUser(user));
        setUser(previewUser);
    };
    React.useEffect(() => {
        if (inputUser && !editmode) {
            setUser(inputUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputUser]);
    return (
        <div className={cx('AvatarWrapper')}>
            <Image
                src={user.ProfileCover}
                customFallback={process.env.REACT_APP_NODE_SERVER_URL + 'img/profile/default.png'}
                alt="profile cover"
                className={cx('ImgProfile')}
            ></Image>
            {editmode && (
                <div className={cx('DivAvatarEditIcon', 'DivProfileEditIcon')} onClick={() => inputRef.current.click()}>
                    <EditAvatarIcon />
                    <form ref={formRef} encType="multipart/form-data">
                        <input
                            ref={inputRef}
                            type="file"
                            name="cover"
                            accept=".jpg,.jpeg,.png,.tiff,.heic,.webp"
                            className={cx('InputUpload')}
                            onChange={handleSubmit}
                        />
                    </form>
                </div>
            )}
        </div>
    );
}

export default ProfileCover;
