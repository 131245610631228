import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import Image from '~/components/Image';
import styles from './AccountItem.module.scss';
const cx = classNames.bind(styles);

function AccountItem({ fullName, nickName, tick, avatar, accName, onClick }) {
    return (
        <Link onClick={onClick} to={'/profile/' + accName}>
            <div className={cx('account-item')}>
                <Image className={cx('avatar')} src={avatar} alt="avatar" />
                <div className={cx('info')}>
                    <h4 className={cx('info-nickName')}>
                        {nickName}
                        {tick && <FontAwesomeIcon className={cx('info-tick')} icon={faCheckCircle}></FontAwesomeIcon>}
                    </h4>
                    <div className={cx('info-fullName')}>{fullName}</div>
                </div>
            </div>
        </Link>
    );
}
AccountItem.propTypes = {
    //  fullName: PropTypes.string.isRequired,
    nickName: PropTypes.string.isRequired,
    tick: PropTypes.bool.isRequired,
    avatar: PropTypes.string.isRequired,
    accName: PropTypes.string.isRequired,
};
export default AccountItem;
