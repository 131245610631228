import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { CloseIcon, EditProfileIcon } from '~/components/Icons';
import { default as Button2 } from '~/components/Button';
import classNames from 'classnames/bind';
import styles from './Profile.module.scss';
import { useStore } from '~/components/Hook';
import Avatar from './Avatar';
import { UploadAvatar } from '~/services/nhanVienService';
import ProfileCover from './ProfileCover';
import { IconButton } from '@mui/material';
const cx = classNames.bind(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function EditProfileDialog() {
    const store = useStore();
    const [open, setOpen] = React.useState(false);
    //const Transition = <Slide direction="up"  />;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        store.storage.setData({ previewAvatar: undefined, previewCover: undefined });
        setOpen(false);
    };

    const handleSubmit = async () => {
        var bodyFormData = new FormData();
        bodyFormData.append('userName', 'Fred');
        bodyFormData.append('avatar', store.storage.data.previewAvatar);
        bodyFormData.append('cover', store.storage.data.previewCover);
        const res = await UploadAvatar(bodyFormData);
        if (res) store.dispatch(store.actions.setUser(res.data));
        store.storage.setData({ previewAvatar: undefined, previewCover: undefined });
        setOpen(false);
    };
    return (
        <div className={cx('DivEditContainer')}>
            <Button2 primary round leftIcon={<EditProfileIcon />} onClick={handleClickOpen}>
                Sửa hồ sơ
            </Button2>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                //keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ padding: '8px 24px' }}>
                    <div className={cx('Dialog-tittle')}>
                        <h2 className={cx('text')}>Sửa hồ sơ</h2>
                        <IconButton onClick={handleClose}>
                            <CloseIcon className={cx('close-icon')} />
                        </IconButton>
                    </div>
                </DialogTitle>
                <span className={cx('linebreak')} />
                <DialogContent>
                    <div className={cx('editAvatar')}>
                        <p className={cx('text')}>Ảnh hồ sơ</p>
                        <Avatar editmode={true}></Avatar>
                    </div>
                    <div className={cx('linebreak')} style={{ margin: '12px 0px' }} />
                    <div className={cx('editAvatar')}>
                        <p className={cx('text')}>Ảnh bìa</p>
                        <ProfileCover editmode={true}></ProfileCover>
                    </div>
                </DialogContent>
                <span className={cx('linebreak')} />
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button2 round onClick={handleSubmit}>
                        Cập nhật
                    </Button2>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditProfileDialog;
