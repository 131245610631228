import { UpdateLinkUser } from '~/components/store/reducer';
import nodeRequest from '~/ulti/nodeRequest';

export const search = async (q, type = 'less') => {
    try {
        const res = await nodeRequest.get('user/search', {
            params: {
                q,
                type,
            },
        });
        const results = [];
        res.data.map((item) => results.push(UpdateLinkUser(item)));
        return results;
    } catch (error) {
        console.log(error);
    }
};
