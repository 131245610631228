import { useState } from 'react';
import classNames from 'classnames/bind';
import Popup from 'reactjs-popup';

import RegisterForm from './RegisterForm';

import styles from './Register.module.scss';
import Button from '~/components/Button';

const cx = classNames.bind(styles);

function Register() {
    const [show, setShow] = useState(false);
    const handleClick = () => {
        setShow((prev) => !prev);
    };
    const closeModal = () => setShow(false);
    return (
        <div className={cx('wrapper')}>
            <Button primary onClick={handleClick}>
                Login
            </Button>
            <Popup open={show} position="right center" onClose={closeModal}>
                <RegisterForm />
            </Popup>
        </div>
    );
}

export default Register;
