import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styles from './Profile.module.scss';
import classNames from 'classnames/bind';
import ProfileHeader from './ProfileHeader';
import { useStore } from '~/components/Hook';
import { getUserByUsername } from '~/services/nhanVienService';
import ProfileBody from './ProfileBody';

const cx = classNames.bind(styles);
function Profile() {
    const id = useParams();
    const store = useStore();
    const [user, setUser] = useState(undefined);
    const currentUser = store.state.user;
    useEffect(() => {
        if (id.username === 'me') setUser({ ...currentUser, jobs: store.storage.data.jobs });
        else {
            const getUser = async (userName) => {
                const loaduser = await getUserByUsername(userName);
                if (loaduser) setUser(loaduser);
            };
            getUser(id.username);
        }
    }, [id, currentUser, store.storage.data.jobs]);
    return (
        <div key={user} className={cx('wrap')}>
            {user && currentUser && (
                <>
                    <ProfileHeader user={user}></ProfileHeader>
                    <ProfileBody jobs={user.jobs}></ProfileBody>
                </>
            )}
        </div>
    );
}

export default Profile;
