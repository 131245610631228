import { useMemo, useReducer } from 'react';
import Context from './Context';
import reducer, { initState } from './reducer';

function Provider({ children }) {
    const [state, dispatch] = useReducer(reducer, initState);
    const storage = useMemo(() => {
        const setData = (newdata) => {
            result.data = { ...result.data, ...newdata };
        };
        const result = { data: { jobs: [] }, setData: setData };
        return result;
    }, []);

    return <Context.Provider value={[state, dispatch, storage]}>{children}</Context.Provider>;
}

export default Provider;
