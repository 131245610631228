import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TimeSheetRow from './TimeSheetRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#384c70',
        color: theme.palette.common.white,
        fontSize: 16,
        padding: '4px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        height: 36,
        padding: '4px',
    },
    [`&.ts_project`]: {
        color: '#0066cc',
        padding: '4px 8px',
        lineHeight: '24px',
    },

    [`&.ts_overtime`]: {
        color: 'red',
    },
    [`&.main`]: { fontWeight: 700 },
    [`&.subclose`]: {
        paddingBottom: 0,
        paddingTop: 0,
        border: 0,
        height: 0,
    },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => {
    return {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        '&.CN': {
            backgroundColor: '#fabf8f',
        },
    };
});
function CustomizedTables({ rows }) {
    return (
        <TableContainer
            sx={{
                marginBottom: '16px',
            }}
            component={Paper}
        >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="customized table">
                <colgroup>
                    <col style={{ width: '2%' }} />
                    <col style={{ width: '14%', minWidth: '128px' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '46%' }} />
                </colgroup>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">No.</StyledTableCell>
                        <StyledTableCell align="center">Date</StyledTableCell>
                        <StyledTableCell align="center">Work Time</StyledTableCell>
                        <StyledTableCell align="center">Over Time</StyledTableCell>
                        <StyledTableCell align="center">Project</StyledTableCell>
                        <StyledTableCell align="center">Start Work</StyledTableCell>
                        <StyledTableCell align="center">End Work</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TimeSheetRow key={row.Id} row={row}></TimeSheetRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CustomizedTables;
